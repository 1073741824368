<template>
  <b-overlay :show="loading" class="row">
    <div class="col-12 col-lg-12 c">
      <div class="card">
        <div class="card-header">
          <h4>
            <i class="fa fa-users"></i>
            استعراض الارقام
          </h4>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="form-group col-12 col-lg-4 c g">
              <label for="">اختر المجموعة</label>
              <select class="form-control" v-model="group_id">
                <option :value="'all'">الكل</option>
                <option :value="null">بدون مجموعة</option>
                <option
                  v-for="group in groups"
                  :key="group._id"
                  :value="group._id"
                >
                  {{ group.title }}
                </option>
              </select>
            </div>
            <div class="form-group col-12 col-lg-4 c g">
              <label for="">بحث</label>
              <input
                type="text"
                class="form-control"
                v-model="q"
                placeholder="ابحث هنا..."
              />
            </div>
            <div class="col-12 text-center">
              <button
                class="btn btn-sm btn-primary"
                @click="
                  searched = true;
                  getContacts();
                "
              >
                <i class="fa fa-search"></i>
                عرض الأرقام
              </button>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div
            class="alert alert-info text-center g"
            v-if="searched && contacts.length == 0"
          >
            لا يوجد ارقام لعرضها بالخيارات المختارة.
          </div>
          <div class="alert alert-success text-center g" v-if="!searched">
            اختر المجموعة او كلمات البحث واضغط على "<i class="fa fa-search"></i>
            عرض الأرقام"
          </div>
          <div class="col-12 table-responsive" v-if="contacts.length > 0">
            <button class="btn btn-relief-primary" @click="checkWhatsapp()">
              <span v-if="!checkWhatsappBtnLoader"
                ><i class="fa fa-whatsapp"></i> التأكد من ان الأرقام عليها
                واتسآب</span
              >
              <span v-if="checkWhatsappBtnLoader">
                <div class="spinner-border" role="status"></div>
                جاري المعالجة...
                <div v-if="checkWhatsappBtnLoaderText">
                  <br />
                  <small
                    ><i>{{ checkWhatsappBtnLoaderText }}</i></small
                  >
                </div>
              </span>
            </button>
            &nbsp;
            <button class="btn btn-secondary" @click="exporta()">
              <i class="fa fa-download"></i>
              تصدير الأرقام المعروضة إكسل
            </button>
            <br />
            <br />
            <table class="table table-hover" id="expoerme">
              <thead>
                <th class="dnone"></th>
                <th>جهة الاتصال</th>
                <th>لديه واتسآب</th>
                <th>المجموعة</th>
                <th>خيارات</th>
              </thead>
              <tbody>
                <tr v-for="contact in contacts" :key="contact._id">
                  <td class="dnone">
                    {{ contact.phone }}
                  </td>
                  <td>
                    <span v-if="contact.name != ''"
                      >{{ contact.name }}<br
                    /></span>
                    <div class="hideme" v-if="shownumber">{{ contact.phone }}</div>
                  </td>
                  <td>
                    <span
                      v-if="contact.has_whatsapp == 0"
                      class="badge bg-warning"
                    >
                      لم يتم التأكد
                    </span>
                    <span
                      v-if="contact.has_whatsapp == 1"
                      class="badge bg-primary"
                    >
                      لديه واتسآب
                    </span>
                    <span
                      v-if="contact.has_whatsapp == 2"
                      class="badge bg-danger"
                    >
                      ليس لديه واتسآب
                    </span>
                  </td>
                  <td>
                    <span v-if="contact.group_id == ''"> بدون مجموعة </span>
                    <span v-if="contact.group_id != ''">
                      {{ titleOfGroup(contact.group_id) }}
                    </span>
                  </td>
                  <td>
                    <b-dropdown
                      id="dropdown-1"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      text="خيارات"
                      variant="primary"
                      size="sm"
                    >
                      <b-dropdown-item
                        @click="$router.push('/archive#' + contact.phone)"
                      >
                        <i class="fa fa-archive"></i>
                        ارشيف الرسائل
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-b-modal.modal-1
                        @click="current = contact"
                      >
                        <i class="fa fa-edit"></i>
                        تعديل
                      </b-dropdown-item>
                      <b-dropdown-item @click="deleteContact(contact._id)">
                        <i class="fa fa-trash"></i>
                        حذف
                      </b-dropdown-item>
                    </b-dropdown>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- basic modal -->
    <b-modal id="modal-1" title="تعديل جهة الاتصال" hide-footer>
      <div class="form-group">
        <label for="">الاسم</label>
        <input type="text" class="form-control" v-model="current.name" />
      </div>
      <div class="form-group">
        <label for="">الهاتف</label>
        <input type="text" class="form-control" v-model="current.phone" />
      </div>
      <div class="form-group">
        <label for="">المجموعة</label>
        <select class="form-control" v-model="current.group_id">
          <option value="">بدون مجموعة</option>
          <template v-for="group in groups">
            <option :value="group._id" :key="group._id">
              {{ group.title }}
            </option>
          </template>
        </select>
      </div>
      <div class="col-12 text-center">
        <button class="btn btn-success" @click="editContactCurrent()">
          تعديل <i class="fa fa-arrow-left"></i>
        </button>
      </div>
    </b-modal>
  </b-overlay>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BOverlay,
  BModal,
  VBModal,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BOverlay,
    BModal,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
  data() {
    return {
      contacts: [],
      current: {},
      group_id: "all",
      searched: false,
      groups: [],
      q: null,
      loading: true,
      shownumber: true,
      groupsObj: {},
      user: JSON.parse(localStorage.getItem("user")),
      checkWhatsappBtnLoader: false,
      checkWhatsappBtnLoaderText: null,
    };
  },
  created() {
    var g = this;
    if (window.location.hash) {
      g.group_id = window.location.hash.split("#")[1] ?? "";
      if (window.location.hash == "#empty") {
        g.group_id = "";
      }
      g.searched = true;
      g.getContacts();
    }
    $.post(api + "/user/contacts/groups/list", {
      jwt: this.user.jwt,
    })
      .then(function (r) {
        g.groups = r.response;
        g.loading = false;
        g.groups.forEach((element) => {
          g.groupsObj[element._id] = element;
        });
      })
      .catch(function () {
        g.loading = false;
        g.$toast({
          component: ToastificationContent,
          props: {
            title: "حدثت مشكلة في الاتصال",
            icon: "EditIcon",
            variant: "danger",
          },
        });
      });
  },
  methods: {
    titleOfGroup(group_id){
      if(this.groupsObj[group_id]){
        return this.groupsObj[group_id].title
      }
    },
    exporta(){
      var g =this;
      g.shownumber = false;
   setTimeout(() => {
     var table_id = "expoerme"
    var separator = ','
      // Select rows from table_id
    var rows = document.querySelectorAll('table#' + table_id + ' tr');
    // Construct csv
    var csv = [];
    for (var i = 0; i < rows.length; i++) {
        var row = [], cols = rows[i].querySelectorAll('td, th');
        for (var j = 0; j < cols.length; j++) {
            // Clean innertext to remove multiple spaces and jumpline (break csv)
            var data = cols[j].innerText.replace(/(\r\n|\n|\r)/gm, '').replace(/(\s\s)/gm, ' ')
            // Escape double-quote with double-double-quote (see https://stackoverflow.com/questions/17808511/properly-escape-a-double-quote-in-csv)
            data = data.replace(/"/g, '""');
            // Push escaped string
            row.push('"' + data + '"');
        }
        csv.push(row.join(separator));
    }
    var csv_string = csv.join('\n');
    // Download it
    var filename = 'smart-whats.com_' + new Date().toLocaleDateString() + Math.random().toString().split(".")[1].substring(0,5) + '.csv';
    var link = document.createElement('a');
    link.style.display = 'none';
    link.setAttribute('target', '_blank');
    link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv_string));
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setTimeout(() => {
      
    g.shownumber = true
    }, 5000);
   }, 1000);
    },
    getContacts() {
      var g = this;
      g.loading = true;
      $.post(api + "/user/contacts/list", {
        jwt: this.user.jwt,
        q: g.q,
        group_id: g.group_id,
      })
        .then(function (r) {
          g.contacts = r.response;
          g.loading = false;
        })
        .catch(function () {
          g.loading = false;
          g.$toast({
            component: ToastificationContent,
            props: {
              title: "حدثت مشكلة في الاتصال",
              icon: "EditIcon",
              variant: "danger",
            },
          });
        });
    },
    checkWhatsapp() {
      var g = this;
      if (!this.checkWhatsappBtnLoader) {
        this.checkWhatsappBtnLoader = true;
        $.post(api + "/user/contacts/check-registered", {
          jwt: this.user.jwt,
        })
          .then(function (r) {
            if (r.status == 100) {
              setTimeout(() => {
                g.checkWhatsappBtnLoaderText =
                  "جاري المعالجة الآن.. اوشكنا على الإنتهاء";
              }, 5000);
              function OrderCheck() {
                $.post(api + "/user/contacts/check-registered-order", {
                  jwt: g.user.jwt,
                  order_id: r.response,
                })
                  .then(function (res) {
                    if (res.response) {
                      g.checkWhatsappBtnLoader = false;
                      g.$toast({
                        component: ToastificationContent,
                        props: {
                          title: "تم التأكد من الارقام بنجاح",
                          icon: "CheckIcon",
                          variant: "success",
                        },
                      });
                      g.getContacts();
                    } else {
                      setTimeout(() => {
                        OrderCheck();
                      }, 3000);
                    }
                  })
                  .catch(function () {
                    setTimeout(() => {
                      OrderCheck();
                    }, 5000);
                  });
              }
              OrderCheck();
            } else {
              g.checkWhatsappBtnLoader = false;
              g.$toast({
                component: ToastificationContent,
                props: {
                  title: r.response,
                  icon: "XIcon",
                  variant: "danger",
                },
              });
            }
          })
          .catch(function () {
            g.checkWhatsappBtnLoader = false;
            g.$toast({
              component: ToastificationContent,
              props: {
                title: "حدثت مشكلة في الاتصال",
                icon: "XIcon",
                variant: "danger",
              },
            });
          });
      }
    },
    deleteContact(id) {
      if (confirm("متأكد من الحذف؟")) {
        var g = this;
        $.post(api + "/user/contacts/delete", {
          jwt: this.user.jwt,
          id: id,
        })
          .then(function (r) {
            g.$toast({
              component: ToastificationContent,
              props: {
                title: "تم الحذف بنجاح",
                icon: "CheckIcon",
                variant: "success",
              },
            });
            g.getContacts();
          })
          .catch(function () {
            g.$toast({
              component: ToastificationContent,
              props: {
                title: "حدثت مشكلة",
                icon: "XIcon",
                variant: "danger",
              },
            });
          });
      }
    },
    editContactCurrent() {
      var g = this,
        current = g.current;
      $.post(api + "/user/contacts/edit", {
        jwt: this.user.jwt,
        id: current._id,
        name: current.name,
        group_id: current.group_id,
        phone: current.phone,
      })
        .then(function (r) {
          g.$toast({
            component: ToastificationContent,
            props: {
              title: "تم التعديل بنجاح",
              icon: "CheckIcon",
              variant: "success",
            },
          });
          g.getContacts();
          $("#modal-1___BV_modal_header_ > button").click();
        })
        .catch(function () {
          g.$toast({
            component: ToastificationContent,
            props: {
              title: "حدثت مشكلة",
              icon: "XIcon",
              variant: "danger",
            },
          });
        });
    },
  },
};
</script>